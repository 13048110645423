<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap dense>
                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.nombre"
                                          label="Nombre de campo">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.campoTramiteName"
                                          label="Nombre de campo">
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancela</v-btn>
            <v-btn color="blue darken-1" text @click="saveItem">Guarda</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'campoTramiteDialogo',
    data() {
        return {
            value: [1, 2, 5]
        };
    },

    computed: {
        ...mapState('campotramite', ['item']),

        formTitle() {
            return this.index === -1 ? 'Nuevo Campo' : 'Edita Campo';
        }
    },

    methods: {
        ...mapActions('campotramite', ['closeDialog', 'saveItem']),

        close() {
            this.closeDialog();
        }
    },

    created() {}
};
</script>
