<template>
    <campoTramiteCrud></campoTramiteCrud>
</template>

<script>
import campoTramiteCrud from '@/components/CampoTramite/CampoTramiteCrud';

export default {
    name: 'campoTramite',
    data() {
        return {};
    },
    components: {
        campoTramiteCrud
    }
};
</script>
